import React from "react";


const TransactionTableHeader = ({transaction, isOutcome}) => {
  return (
    <div className="row">
      <div className="col">
        <div className="row flex-column" style={{textAlign: 'left'}}>
          <div className="col">
            <h3>
              # {transaction.id} - {transaction.description}
              {/*{transaction.is_confirmed && (<span className='confirm-badge'>&#10003;</span>)}*/}
            </h3>
          </div>
          {/*{isOutcome && <div className="col"><p><b>Погоджено:</b> {transaction.is_confirmed ? 'Так' : 'Ні'}</p></div>}*/}
          <div className="col"><p><b>Статус:</b> {transaction.status.label || ''}</p></div>
          <div className="col"><p><b>Тип:</b> {transaction.type || '?'}</p></div>
          <div className="col"><p><b>Дата:</b> {transaction.date || ''}</p></div>
          <div className="col"><p><b>Автор:</b> {transaction.manager || '?'}</p></div>
          {/*{transaction.payer && <div className="col"><p><b>Платник:</b> {transaction.payer.label || '?'}</p></div>}*/}
          <div className="col"><p><b>Коментар:</b> {transaction.comment || '-'}</p></div>
        </div>
      </div>
    </div>
  )
};

export default TransactionTableHeader;