import React, {memo, useState, useEffect, useContext} from "react";

import {useParams, useNavigate} from "react-router-dom";

import Form from "../../Components/Form";
import Input from "../../Components/Input";
import APIService from "../../services/api";
import {useSnackbar} from "../../hooks";
import {ProjectContext} from "../../context";

const getToday = () => {
    const today = new Date();
    const month = `${today.getMonth() + 1}`.padStart(2, '0');
    const day = `${today.getDate()}`.padStart(2, '0');
    return `${today.getFullYear()}-${month}-${day}`;
}

const ProjectTransactionAdd = ({type, onTransactionAdd}) => {
    const [date, setDate] = useState(getToday());
    const [tax, setTax] = useState(null);
    const [description, setDescription] = useState('');
    const [errors, setErrors] = useState(null);

    const urlParams = useParams();
    const navigate = useNavigate();
    const openSnackbar = useSnackbar();
    const {project} = useContext(ProjectContext);

    const api = new APIService();

    // useEffect(() => {
    //     project && setTax(project.tax.value);
    // }, []);
    //
    // useEffect(() => {
    //     project && setTax(project.tax.value);
    // }, [project]);

    const addTransaction = async () => {
        let transaction = null;
        try {
            transaction = await api.addTransaction(urlParams.projectId, {type, date, tax, description});
        } catch (e) {
            e.data ? setErrors(e.data) : openSnackbar(e.message);
        }

        if (transaction) {
            navigate(`/project/${urlParams.projectId}/transaction/${transaction.id}`);
            onTransactionAdd();
        }
    }

    if (!project) return null;

    return (
        <Form
            headerText='Створення транзакції'
            submitButtonText='Створити'
            onSubmit={addTransaction}
            error={errors && errors['non_field_errors']}
        >
            <div className="row">
                <div className="col">
                    <Input
                        value={date}
                        type={'date'}
                        placeholder="Дата"
                        error={errors && errors['date']}
                        onChange={e => setDate(e.target.value)}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Input
                        value={description}
                        type={'text'}
                        placeholder="Призначення"
                        error={errors && errors['description']}
                        onChange={e => setDescription(e.target.value)}
                    />
                </div>
            </div>
        </Form>
    )
}

export default memo(ProjectTransactionAdd);